var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"knowledge-base-home-container normalpage-container"},[_c('aside',{staticClass:"left-warp"},[_c('div',{staticClass:"menu-list-warp"},_vm._l((_vm.leftSideMeunList),function(item){return _c('div',{key:item.key,staticClass:"menu-item",class:_vm.currentMenu === item ? 'active' : '',on:{"click":function($event){$event.stopPropagation();return _vm.handleMenuItemClick(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentMenu.key === '1'),expression:"currentMenu.key === '1'"}],staticClass:"tree-warp"},[_c('ClassificationTree',{ref:"ClassificationTree",on:{"change":function($event){return _vm.getList()}},model:{value:(_vm.typeID),callback:function ($$v) {_vm.typeID=$$v},expression:"typeID"}})],1)]),_c('section',{staticClass:"right-warp"},[_c('div',{staticClass:"right-header"},[_c('div',{staticClass:"active-title"},[_c('div',{staticClass:"title-icon"}),_vm._v(" "+_vm._s(_vm.currentMenu.name)+" ")]),_c('div',{staticClass:"creat-action"},[(_vm.judgingAPermission(['konwledge.store'], []))?_c('el-button',{attrs:{"size":"medium","type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.doCreatKonwledgeBase.apply(null, arguments)}}},[_vm._v("创建知识库")]):_vm._e()],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],staticClass:"right-main"},[(
						Array.isArray(_vm.knowledgeList) && _vm.knowledgeList.length && (_vm.currentMenu.key === '1' || _vm.currentMenu.key === '2')
					)?[_c('div',{staticClass:"main-warp"},_vm._l((_vm.knowledgeList),function(item){return _c('div',{key:item.id,staticClass:"item",on:{"click":function($event){$event.stopPropagation();return _vm.handleKonwledgeItemClick(item)}}},[_c('div',{staticClass:"cover",style:(`background-image: url(${
									Array.isArray(item.cover) && item.cover.length ? item.cover[0].url : ''
								})`)},[_c('svg',{staticClass:"fl-icon",attrs:{"t":"1661140683420","viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","p-id":"30027","width":"24","height":"24"}},[_c('path',{attrs:{"d":"M832 96a64 64 0 0 1 64 64v744.32a32 32 0 0 1-48.128 27.584L512 736l-335.872 195.904A32 32 0 0 1 128 904.32V160a64 64 0 0 1 64-64h640z m0 64H192v688.576l303.872-177.28a32 32 0 0 1 32.256 0L832 848.64V160zM672 320v64h-320V320h320z","fill":"#fff","p-id":"30028"}})]),_c('div',{staticClass:"type"},[_vm._v(_vm._s(item.is_show === 1 ? '公开' : '私有'))]),_c('div',{staticClass:"delete"},[_c('i',{staticClass:"iconfont iconwangpan-fenxiang1x",staticStyle:{"margin-right":"4px"},on:{"click":function($event){$event.stopPropagation();return _vm.handleShareClick(item)}}}),(!item.permission || item.permission.includes('edit'))?_c('i',{staticClass:"iconfont icongis_shanchu",on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteClick(item)}}}):_vm._e()])]),_c('div',{staticClass:"info"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))])])])}),0)]:(
						Array.isArray(_vm.knowledgeList) && _vm.knowledgeList.length && (_vm.currentMenu.key === '3' || _vm.currentMenu.key === '4')
					)?[_c('el-table',{attrs:{"height":"100%","data":_vm.knowledgeList,"show-header":false},on:{"row-click":_vm.handleRowClick}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"file-name-warp"},[(row.type === 'word' || row.type === 'folder')?_c('svg',{staticClass:"flie-icon",attrs:{"aria-hidden":"true","width":"20","height":"20"}},[(row.type === 'word')?_c('use',{attrs:{"xlink:href":"#iconwenben1"}}):(row.type === 'folder')?_c('use',{attrs:{"xlink:href":"#iconwenjianjia1"}}):_vm._e()]):_c('svg',{staticClass:"flie-icon",attrs:{"aria-hidden":"true","width":"20","height":"20"}},[_c('use',{attrs:{"xlink:href":_vm.getFileSvgIcon(row)}})]),_c('span',{staticClass:"file-name"},[_vm._v(" "+_vm._s(row.name))])])]}}])}),_c('el-table-column',{attrs:{"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.user_name)+" ")]}}])}),_c('el-table-column',{attrs:{"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.created_at)+" ")]}}])})],1)]:_c('el-empty',{staticStyle:{"width":"100%","height":"100%"},attrs:{"description":"暂无内容"}})],2)]),_c('el-dialog',{attrs:{"title":"新增知识库","visible":_vm.dialogVisible,"width":"40%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.dialogVisible)?_c('NewKonwledge',{attrs:{"btnLoading":_vm.btnLoading},on:{"submit":_vm.handleSubmit,"relist":_vm.relist}}):_vm._e()],1),_c('el-dialog',{attrs:{"title":"分享","visible":_vm.showShareDialog,"width":"500px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.showShareDialog=$event}}},[(_vm.showShareDialog)?_c('ShareDialog',{attrs:{"infoType":_vm.infoType,"shareId":_vm.shareId,"infoUrl":_vm.infoUrl},on:{"close":function($event){_vm.showShareDialog=false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }