<template>
  <div class="classification-tree-container">
    <div class="tree-header">
      <span class="header-label">知识库分类</span>
      <el-button v-if="judgingAPermission(['knowledge.classification.store'], [])" type="text" @click="open()">
        <i class="iconfont iconxinzeng2"></i>
      </el-button>
    </div>
    <div class="tree-warp">
      <el-tree
        :data="treeData"
        node-key="id"
        draggable
        @node-drag-end="handleDragEnd"
        default-expand-all
        @node-click="handleNodeClick"
        :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ data }">
          <span class="label" :class="value === data.id ? 'active' : ''">{{ data.name }}</span>
          <span @click.stop="" v-if="judgingAPermission(['knowledge.classification.store', 'knowledge.classification.update', 'knowledge.classification.delete'], [])">
            <el-dropdown @command="(command) => { handleCommand(command, data) }" placement="bottom-start">
              <span class="el-dropdown-link">
                <svg width="16" height="16">
                  <use xlink:href="#iconbimgis_gengduo" />
                </svg>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="judgingAPermission(['knowledge.classification.store'], [])" command="new">
                  <span><i class="iconfont iconbim_jia"></i>新增</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="judgingAPermission(['knowledge.classification.update'], [])" command="edit">
                  <span><i class="iconfont iconwangpan-zhongmingming1x"></i>编辑</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="judgingAPermission(['knowledge.classification.delete'], [])" command="delete">
                  <span><i style="color: red" class="iconfont iconwangpan-cuohao1x" />删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </span>
      </el-tree>
    </div>
    <el-dialog
      :title="type=== 'edit'?'编辑':'新增'"
      :visible.sync="dialogVisible"
      width="420px"
      :before-close="onClose">
      <el-form :model="formData">
        <el-form-item label="名称:">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="是否展示:">
          <el-switch v-model="isShow"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doRequest(formData)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Tree, MessageBox, Dropdown, DropdownMenu, DropdownItem, Dialog, Switch } from 'element-ui'
import { knowledgeType } from '@/saas-apis/konwledgeBase.js'
import { judgingAPermission } from '@/utils/tools'
import {dragType} from '@/api/myApps';
export default {
  components: {
    'el-tree': Tree,
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
    'el-dialog':Dialog,
    'el-switch': Switch
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      treeData: [],
      formData: {
        name:'',
      },
      dialogVisible:false,
      type:'edit',
      isShow:false,
    }
  },
  created() {
    this.getTreeData()
  },
  methods: {
    judgingAPermission: judgingAPermission,
    onClose(){
      this.dialogVisible = false;
    },
    handleNodeClick(data) {
      this.$emit('input', data.id)
      this.$emit('change', data.id)
    },
    getTreeData() {
      const REQDATA = {
      }
      knowledgeType.get(REQDATA).then(res => {
        if(res.data.code === 200) {
          this.treeData = res.data.data
        }
      })
    },
		handleDragEnd(draggingNode, dropNode, dropType) {
			if (dropType === 'none') return;
			let parentLength = dropNode.data.all_parent.split(',').length;
			let sort;
			if (dropType === 'before') {
				if (dropNode.data.sort !== 0) {
					sort = dropNode.data.sort - 1;
				} else {
					sort = 0;
				}
			} else if (dropType === 'after') {
				sort = dropNode.data.sort + 1;
			}
			let params = {
				menu_id: draggingNode.data.id,
				parent_id: dropNode.data.all_parent.split(',')[parentLength - 1],
				sort: sort
			};
			if (dropNode.data.children.length) {
				dropNode.data.children.forEach((item, index) => {
					if (item.id === params.menu_id) {
						params.parent_id = dropNode.data.id;
						if (index !== 0) {
							params.sort = dropNode.data.children[index - 1].sort + 1;
						} else {
							params.sort = 0;
						}
					}
				});
			}
      dragType(params).then((res) => {console.log(res)})
		},
    handleCommand(command, data) {
      switch(command) {
        case 'new': {
          this.open(data)
          break
        }
        case 'edit': {
          this.editor(data)
          break
        }
        case 'delete': {
          this.deleteItem(data)
          break
        }
      }
    },
    open(data) {
      this.isShow = false
      if(data){
        this.formData.parent_id = data.id;
        this.formData.name = ''
      }else{
        this.formData ={
          name:'',
          parent_id : 0
        }
      }
      this.dialogVisible = true
      this.type = 'add'
    },
    editor(data) {
      this.isShow = !!data.is_show
      this.formData = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true
      this.type = 'edit'
    },
    doRequest(reqData) {
      const REQUESTMETHOD = reqData.id ? knowledgeType.put : knowledgeType.post
      if(this.isShow){
        reqData.is_show = 1
      }else{
        reqData.is_show = 0
      }
      REQUESTMETHOD(reqData).then(res => {
        this.dialogVisible = false
        if(res.data.code === 200) {
           this.$message.success(res.data.msg)
          this.getTreeData()
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        this.dialogVisible = false
        this.$message.error(err)
      })
    },
    deleteItem(data) {
      MessageBox.confirm(`此操作将永久删除 "${data.name}" , 是否继续?`, '提示', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'class-tree-message-box'
        }).then(() => {
          const res = {
            id: data.id,
          }
          knowledgeType.delete(res).then(res => {
            if(res.data.code === 200) {
              this.$message.success(res.data.msg)
              this.getTreeData()
            }
          }).catch(err => {
            this.$message.error(err)
          })
        }).catch(() => {
          // nothing to do
        })
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.el-form-item){
  display: flex;
  align-items: center;
  .el-form-item__label{
    padding:0 !important;
    margin-right: 16px;
  }
}
.classification-tree-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tree-header{
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-label{
      display: flex;
      align-items: center;
      &::before{
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #409eff;
        margin-right: 8px;
      }
    }
  }
  .tree-warp{
    flex-grow: 1;
    height: 10px;
    overflow-y: auto;
    margin-top: 10px;
    :deep(.el-tree-node__content) {
      .custom-tree-node{
        flex-grow: 1;
        width: 10px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        box-sizing: border-box;
        .label{
          flex-grow: 1;
          width: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .active{
          color: #409eff;
        }
      }
    }
    
  }
}
</style>
<style lang="less">
.class-tree-message-box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: none;
  .el-message-box__header{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 48px;
    background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 68px 0 24px;
    color: #fff;
    .el-message-box__title{
      color: #fff;
      font-size: 14px;
    }
    .el-message-box__headerbtn{
      top: 14px;
      right: 24px;
      .el-message-box__close{
        color: #fff;
          font-size: 20px;
      }
    }
  }
}
</style>