<template>
	<div class="knowledge-base-home-container normalpage-container">
		<aside class="left-warp">
			<div class="menu-list-warp">
				<div
					class="menu-item"
					:class="currentMenu === item ? 'active' : ''"
					@click.stop="handleMenuItemClick(item)"
					v-for="item in leftSideMeunList"
					:key="item.key">
					{{ item.name }}
				</div>
			</div>
			<div
				class="tree-warp"
				v-show="currentMenu.key === '1'">
				<ClassificationTree
          ref="ClassificationTree"
					v-model="typeID"
					@change="getList()" />
			</div>
		</aside>
		<section class="right-warp">
			<div class="right-header">
				<div class="active-title">
					<div class="title-icon" />
					{{ currentMenu.name }}
				</div>
				<div class="creat-action">
					<el-button
						v-if="judgingAPermission(['konwledge.store'], [])"
						size="medium"
						type="primary"
						@click.stop="doCreatKonwledgeBase"
						>创建知识库</el-button
					>
				</div>
			</div>
			<div
				class="right-main"
				v-loading="listLoading">
				<template
					v-if="
						Array.isArray(knowledgeList) && knowledgeList.length && (currentMenu.key === '1' || currentMenu.key === '2')
					">
					<div class="main-warp">
						<div
							class="item"
							v-for="item in knowledgeList"
							:key="item.id"
							@click.stop="handleKonwledgeItemClick(item)">
							<div
								class="cover"
								:style="`background-image: url(${
									Array.isArray(item.cover) && item.cover.length ? item.cover[0].url : ''
								})`">
								<svg
									t="1661140683420"
									class="fl-icon"
									viewBox="0 0 1024 1024"
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									p-id="30027"
									width="24"
									height="24">
									<path
										d="M832 96a64 64 0 0 1 64 64v744.32a32 32 0 0 1-48.128 27.584L512 736l-335.872 195.904A32 32 0 0 1 128 904.32V160a64 64 0 0 1 64-64h640z m0 64H192v688.576l303.872-177.28a32 32 0 0 1 32.256 0L832 848.64V160zM672 320v64h-320V320h320z"
										fill="#fff"
										p-id="30028"></path>
								</svg>
								<div class="type">{{ item.is_show === 1 ? '公开' : '私有' }}</div>
								<div
									class="delete" 
									>
									<i
                    style="margin-right: 4px;"
										class="iconfont iconwangpan-fenxiang1x"
										@click.stop="handleShareClick(item)" />
									<i
                    v-if="!item.permission || item.permission.includes('edit')"
										class="iconfont icongis_shanchu"
										@click.stop="handleDeleteClick(item)" />
								</div>
							</div>
							<div class="info">
								<p class="name">{{ item.name }}</p>
								<p class="desc">{{ item.desc }}</p>
							</div>
						</div>
					</div>
				</template>
				<template
					v-else-if="
						Array.isArray(knowledgeList) && knowledgeList.length && (currentMenu.key === '3' || currentMenu.key === '4')
					">
					<el-table
						height="100%"
						:data="knowledgeList"
						:show-header="false"
						@row-click="handleRowClick">
						<el-table-column>
							<template #default="{ row }">
								<div class="file-name-warp">
									<svg
										v-if="row.type === 'word' || row.type === 'folder'"
										class="flie-icon"
										aria-hidden="true"
										width="20"
										height="20">
										<use
											v-if="row.type === 'word'"
											xlink:href="#iconwenben1" />
										<use
											v-else-if="row.type === 'folder'"
											xlink:href="#iconwenjianjia1" />
									</svg>
									<svg
										v-else
										class="flie-icon"
										aria-hidden="true"
										width="20"
										height="20">
										<use :xlink:href="getFileSvgIcon(row)" />
									</svg>
									<span class="file-name"> {{ row.name }}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="180">
							<template #default="{ row }">
								{{ row.user_name }}
							</template>
						</el-table-column>
						<el-table-column width="180">
							<template #default="{ row }">
								{{ row.created_at }}
							</template>
						</el-table-column>
					</el-table>
				</template>
				<el-empty
					v-else
					style="width: 100%; height: 100%"
					description="暂无内容"></el-empty>
			</div>
		</section>
		<el-dialog
			title="新增知识库"
			:visible.sync="dialogVisible"
			width="40%"
			:close-on-click-modal="false">
			<NewKonwledge
				v-if="dialogVisible"
				:btnLoading="btnLoading"
				@submit="handleSubmit" 
        @relist='relist'
        />
		</el-dialog>
    <el-dialog
    title="分享"
    :visible.sync="showShareDialog"
    width="500px"
    :close-on-click-modal="false"
    >
    <ShareDialog v-if="showShareDialog" :infoType="infoType" :shareId="shareId" :infoUrl="infoUrl" @close="showShareDialog=false"></ShareDialog>
    </el-dialog>
	</div>
</template>
<script>
import {
	getMyRepository,
	getMyCollect,
	getMyfile,
	deleteKonwledge,
	KnowledgeList,
	addKnowledge,
  repositoryShareDetail
} from '@/saas-apis/konwledgeBase.js';
import { defineComponent, reactive, ref } from 'vue';
import { MessageBox, Empty, Message } from 'element-ui';
import { judgingAPermission } from '@/utils/tools';
import Router from '@/router';
import ShareDialog from './components/dialog.vue';
import NewKonwledge from './modal/NewKonwledge';
import ClassificationTree from './components/ClassificationTree';
export default defineComponent({
	components: {
		'el-empty': Empty,
		NewKonwledge,
		ClassificationTree,
    ShareDialog
	},
	// eslint-disable-next-line no-unused-vars
	setup() {
		const leftSideMeunList = ref([
			{ name: '所有文库', key: '1' },
			{ name: '我的文库', key: '2' },
			{ name: '我的收藏', key: '3' },
			{ name: '常用文档', key: '4' }
			// { name: '回收站', key: '5' } // 此功能待确认
		]);
		const currentMenu = ref(leftSideMeunList.value[0]);
		const listLoading = ref(false);
		const knowledgeList = ref([]);
		const typeID = ref('');
    const showShareDialog = ref(false);
		const getList = () => {
			switch (currentMenu.value.key) {
				case '1': {
					getKnowledgeList();
					break;
				}
				case '2': {
					getMyKonwledgeList();
					break;
				}
				case '3': {
					getCollectList();
					break;
				}
				case '4': {
					getCommonlyList();
					break;
				}
			}
		};
		// 所有文库
		const getKnowledgeList = () => {
			listLoading.value = true;
			const reqData = {
				type_id: [typeID.value]
			};
			KnowledgeList(reqData)
				.then((res) => {
					if (res.data.code === 200) {
						knowledgeList.value = res.data.data;
					} else {
						Message.error(res.data.msg);
					}
				})
				.catch((res) => {
					Message.error(res.data.msg);
				})
				.finally(() => {
					listLoading.value = false;
				});
		};
		// 我的文库
		const getMyKonwledgeList = () => {
			listLoading.value = true;
			const reqData = {};
			getMyRepository(reqData)
				.then((res) => {
					if (res.data.code === 200) {
						knowledgeList.value = res.data.data;
					} else {
						Message.error(res.data.msg);
					}
				})
				.catch((res) => {
					Message.error(res.data.msg);
				})
				.finally(() => {
					listLoading.value = false;
				});
		};
		// 我的收藏
		const getCollectList = () => {
			listLoading.value = true;
			const reqData = {};
			getMyCollect(reqData)
				.then((res) => {
					if (res.data.code === 200) {
						knowledgeList.value = res.data.data;
					} else {
						Message.error(res.data.msg);
					}
				})
				.catch((res) => {
					Message.error(res.data.msg);
				})
				.finally(() => {
					listLoading.value = false;
				});
		};
		// 常用文档
		const getCommonlyList = () => {
			listLoading.value = true;
			const reqData = {};
			getMyfile(reqData)
				.then((res) => {
					if (res.data.code === 200) {
						knowledgeList.value = res.data.data;
					} else {
						Message.error(res.data.msg);
					}
				})
				.catch((res) => {
					Message.error(res.data.msg);
				})
				.finally(() => {
					listLoading.value = false;
				});
		};
		getList();
		const handleMenuItemClick = (item) => {
			currentMenu.value = item;
			typeID.value = '';
			getList();
		};
		// vue2 无法使用useRouter
		const handleKonwledgeItemClick = (item) => {
			Router.push({
				name: 'KnowledgeDetails',
				params: {
					id: item.id
				}
			});
		};
    const shareId = ref(0)
    const infoType = ref(1)
    const infoUrl = reactive({
      url:'',
      pwd:''
    })
    const handleShareClick = (item) =>{
      repositoryShareDetail({repository_id:item.id}).then((res)=>{
        shareId.value = item.id;
        if(!res.data.data){
          infoType.value = 1
        }else{
          infoType.value = 2
          infoUrl.url =`https://www.bimcc.com/share?${res.data.data.uuid}`;
          infoUrl.pwd = res.data.data.code;
          infoUrl.time_category = res.data.data.time_category
        }
        showShareDialog.value = true;
      })
    };
		const handleDeleteClick = (item) => {
			MessageBox.confirm('此操作将永久删除该库, 是否继续?', '提示', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				confirmButtonType: 'danger',
				type: 'warning',
				customClass: 'liner-message-box'
			})
				.then(() => {
					handleDeleteItem(item);
				})
				.catch(() => {
					// nothing to do
				});
		};
		const handleDeleteItem = (item) => {
			const reqData = {
				id: item.id
			};
			deleteKonwledge(reqData)
				.then((res) => {
					if (res.data.code === 200) {
						Message.success(res.data.msg);
						getList();
					} else {
						Message.error(res.data.msg);
					}
				})
				.catch((res) => {
					Message.error(res.data.msg);
				});
		};
		const dialogVisible = ref(false);
		const btnLoading = ref(false);
		const doCreatKonwledgeBase = () => {
			dialogVisible.value = true;
		};
		// 表单提交
		const handleSubmit = (res) => {
			btnLoading.value = true;
			addKnowledge(res)
				.then((res) => {
					if (res.data.code === 200) {
						Message.success(res.data.msg);
						getList();
						dialogVisible.value = false;
					} else {
						Message.error(res.data.msg);
					}
				})
				.catch((res) => {
					Message.error(res.data.msg);
				})
				.finally(() => {
					btnLoading.value = false;
				});
		};
		// 文件类型icon
		const fileTypeHash = {
			bmp: '#iconzhaopian1x',
			jpg: '#iconzhaopian1x',
			png: '#iconzhaopian1x',
			gif: '#iconzhaopian1x',
			jpeg: '#iconzhaopian1x',
			cdr: '#iconzhaopian1x',
			psd: '#iconzhaopian1x',
			doc: '#iconword1x',
			docx: '#iconword1x',
			pdf: '#iconpdf1x',
			ppt: '#iconppt1x',
			pptx: '#iconppt1x',
			xlsx: '#iconexcle1x',
			xls: '#iconexcle1x',
			txt: '#icontxt1x',
			mp3: '#iconyinle',
			wav: '#iconyinle',
			mp4: '#iconzhihuigongdi',
			webm: '#iconzhihuigongdi',
			ogg: '#iconzhihuigongdi'
		};
		// 获取文件icon
		const getFileSvgIcon = (data) => {
			if (!data.details) return;
			const arr = data.details?.path?.split('.');
			const pre = arr[arr.length - 1];
			return fileTypeHash[pre.toLocaleLowerCase()] || '#iconother1x';
		};
		const handleRowClick = (row) => {
			Router.push({
				name: 'KnowledgeDetails',
				params: {
					id: row.repository_id
				},
				query: {
					pageId: row.id
				}
			});
		};
    // const emit = defineEmits(['relist'])
    // 刷新右侧树
    const ClassificationTree = ref(null)
    // 延迟使用，因为还没有返回跟挂载
    // nextTick(() => {
    //   ClassificationTree.value.show()
    // })
    const relist = ()=> {
      ClassificationTree.value.getTreeData()
    }
		return {
			leftSideMeunList,
			currentMenu,
			handleDeleteClick,
			handleDeleteItem,
			handleMenuItemClick,
			listLoading,
			knowledgeList,
			doCreatKonwledgeBase,
			dialogVisible,
			btnLoading,
			handleSubmit,
			handleKonwledgeItemClick,
			getFileSvgIcon,
			handleRowClick,
			typeID,
			getList,
      handleShareClick,
      showShareDialog,
      infoType,
      shareId,
      infoUrl,
      relist,
      ClassificationTree
		};
	},
	methods: {
		judgingAPermission: judgingAPermission
	}
});
</script>


<style lang="less" scoped>
@activeColor: #409eff;
@second-color: #999;
@import '../../../css/manageAdd.less';
.knowledge-base-home-container {
	width: 100%;
	height: 100%;
	display: flex;
	cursor: default;
	background: #fff;
	text-align: left;
	border-radius: 8px;
	.left-warp {
		width: 300px;
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		border-right: 1px solid #f1f1f1;
		.menu-list-warp {
			flex-shrink: 0;
			padding: 10px;
			box-sizing: border-box;
			overflow-y: auto;
			.menu-item {
				padding-left: 32px;
				display: flex;
				height: 40px;
				align-items: center;
				cursor: pointer;
				&:hover {
					background: #f7f7f7;
				}
			}
			.active {
				background: #f7f7f7;
				color: @activeColor;
				border-radius: 3px;
			}
		}
		.tree-warp {
			padding: 10px;
			border-top: 1px solid #f1f1f1;
			flex-grow: 1;
			height: 10px;
		}
	}
	.right-warp {
		margin-left: 20px;
		flex-grow: 1;
		padding-right: 32px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		.right-header {
			height: 60px;
			flex-shrink: 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: 10px;
			box-sizing: border-box;
			border-bottom: 1px solid #f1f1f1;
			.active-title {
				display: flex;
				align-items: center;
				font-size: 1.5em;
				font-weight: 600;
				padding: 10px;
				box-sizing: border-box;
				.title-icon {
					width: 20px;
					height: 20px;
					margin-right: 10px;
					border-radius: 50% / 100%;
					background: @activeColor;
				}
			}
		}
		.right-main {
			flex-grow: 1;
			height: 10px;
			padding: 10px;
			box-sizing: border-box;
			.main-warp {
				height: 100%;
				width: 100%;
				padding: 16px 10px;
				box-sizing: border-box;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr;
				grid-template-rows: repeat(auto-fill, 220px);
				gap: 18px;
				overflow-y: auto;
				.item {
					box-shadow: 0px 3px 6px 1px #f1f1f1;
					cursor: pointer;
					overflow: hidden;
					.cover {
						width: 100%;
						height: 160px;
						border-radius: 5px 5px 0 0;
						position: relative;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						.fl-icon {
							position: absolute;
							top: 10px;
							left: 10px;
						}
						.type {
							position: absolute;
							bottom: 5px;
							right: 5px;
							padding: 5px 8px;
							box-sizing: border-box;
							border-radius: 5px;
							background: rgba(12, 12, 12, 0.6);
							color: #fff;
							font-size: 10px;
						}
						.delete {
							position: absolute;
							top: 5px;
							right: 5px;
							padding: 5px 8px;
							box-sizing: border-box;
							color: #fff;
							visibility: hidden;
							font-size: 16px;
							&:hover {
								transform: scale(1.1);
							}
						}
						&:hover {
							.delete {
								visibility: visible;
							}
						}
					}
					.info {
						padding: 10px;
						box-sizing: border-box;
						text-align: left;
						height: 60px;
						.name {
							width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.desc {
							margin-top: 12px;
							color: @second-color;
							width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					&:hover {
						position: relative;
						transform: translateY(-5px) scale(1.02);
						box-shadow: 0px 3px 6px 3px #f1f1f1;
					}
				}
			}
			.file-name-warp {
				display: flex;
				align-items: center;
				.file-name {
					margin-left: 10px;
				}
			}
		}
	}
}
</style>
<style lang="less">
.liner-message-box {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border: none;
	.el-message-box__header {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		height: 48px;
		background: linear-gradient(90deg, #6292ff 0%, #de93ff 84%, #ce92ff 91%, #c568ff 100%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 68px 0 24px;
		color: #fff;
		.el-message-box__title {
			color: #fff;
			font-size: 14px;
		}
		.el-message-box__headerbtn {
			top: 14px;
			right: 24px;
			.el-message-box__close {
				color: #fff;
				font-size: 20px;
			}
		}
	}
}
</style>
